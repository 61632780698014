.read-more-button{
  font-weight: 500;
  margin-top: 5px!important;
  cursor: pointer;
  color: #1F97D1;
  display: block !important;
  &:hover{
    color: inherit;
  }
}
h5{
  line-height: 1.3 !important;
}