.custtooltip{
  max-width: 260px
}
.custtooltip-trigger{
  cursor: pointer;
}
.no-label{
  label{
    display: none;
  }
}